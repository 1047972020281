<template>
  <v-app dark>
    <div class="app-view">
      <app-header class="app-view-header"/>
      <div id="app-view-content" class="radiant">
        <loading v-if="isLoading"/>
        <energy-status-view v-else
          :model="energyStatusViewModel"
        />
      </div>
    </div>
    <v-snackbar
      class="toast"
      :value="toast"
      :color="toast && toast.color"
      :timeout="toast && toast.timeout"
      :vertical="true"
    >
      <div class="toast-text" v-html="toast && toast.message || ''"></div>
      <v-btn dark flat @click="setToast(null)" class="toast-button">
        {{ 'common.button.close' | translate}}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import AppHeader from './components/AppHeader.vue'
import Loading from './components/Loading.vue'

import EnergyStatusView from '../../shared-components/src/components/energyStatusView/EnergyStatusView.vue'

import { ToastModel } from './store/modules/app/models'

import { EnergyStatusViewModel } from '../../shared-components/src/components/energyStatusView/models'

const App = namespace('app')

@Component({
  components: {
    AppHeader,
    Loading,
    EnergyStatusView,
  },
})
export default class AppComponent extends Vue {
  @App.Getter public energyStatusViewModel: EnergyStatusViewModel
  @App.Getter public isLoading: ToastModel | null
  @App.Getter public toast: ToastModel | null
  @App.Mutation public setToast: (toast: ToastModel | null) => void
}
</script>

<style lang="scss">
* {
  user-select: none
}
input[type="text"], input[type="number"] {
  user-select: text;
}
html, body, #app, .application--wrap {
  height: 100% !important;
  min-height: 100% !important;
  font-family: 'Titillium Web', sans-serif;
}
.v-snack__content {
  padding: 10px !important;
}
body, .application, .application--wrap {
  font-family: 'Titillium Web', sans-serif !important;
}
body {
  background: radial-gradient(#1a3856, #080e19) !important;
}
.radiant {
  background: radial-gradient(#1a3856, #080e19);
  margin-top: 0px !important;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: #020407;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #1a3856;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #1a3856;
}

.app-view {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1 0 0;
  overflow: auto;
  .app-view-header {
    flex: 0;
    min-height: 54px;
    height: 54px;
    border-bottom: 1px solid #000;
  }
  #app-view-content {
    flex: 1;
    margin-top: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; 
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  #app-view-content::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
}

</style>
<style lang="css">
html, body, main, #app, .application--wrap .content--wrap {
  height: 100% !important;
  min-height: 100% !important;
}
</style>
