import { ToastModel } from './models'

export interface AppState {
  isLoading: boolean
  selectedLanguage: string
  toast: ToastModel | null
}

export enum AppAction {
  load = 'load',
  loadApiData = 'loadApiData',
  loadStatusViewData = 'loadStatusViewData',
  toastInfo = 'toastInfo',
  toastWarning = 'toastWarning',
  toastError = 'toastError',
  hideToast = 'hideToast',
  handleApiError = 'handleApiError',
  handleHubReconnecting = 'handleHubReconnecting',
  handleHubReconnected = 'handleHubReconnected',
}

export enum AppMutation {
  setLoading = 'setLoading',
  setLanguage = 'setLanguage',
  setToast = 'setToast',
}

export enum AppGetter {
  projectName = 'projectName',
  energyStatusViewModel = 'energyStatusViewModel',
  isLoading = 'isLoading',
  selectedLanguage = 'selectedLanguage',
  toast = 'toast',
}
