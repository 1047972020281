import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import storeLogger from './storeLogger'
import { RootState } from './types'

import { modules as domainModules } from '@ecocoach/domain-store-modules/src'
import { app } from './modules/app'

Vue.use(Vuex)

const storeOptions: StoreOptions<RootState> = {
  modules: {
    // domain modules
    ...domainModules.resourceModule,
    ...domainModules.energyStatusModule,

    // application modules
    app,
  },
  plugins: [ storeLogger ],
}

export default new Vuex.Store<RootState>(storeOptions)
