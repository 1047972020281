import { RootState } from '@/store/types'
import { ApiErrorConverterResult } from '@ecocoach/domain-store-modules/src/helpers/apiErrorConverter'
import { ResourceCategory } from '@ecocoach/domain-store-modules/src/resource/models'
import { ResourceAction, ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { ActionTree } from 'vuex'
import { EnergyStatusAction, EnergyStatusMutation } from '../../../../../eco-domain-store-modules/src/energyStatus/types'
import { ToastModel } from './models'
import { AppAction, AppMutation, AppState } from './types'

const resourceCategories = [
  ResourceCategory.APP_ICONS,
  ResourceCategory.DEVICE_ICONS,
]

export const actions: ActionTree<AppState, RootState> = {
  async [AppAction.load]({ commit, dispatch }, projectId: string): Promise<void> {
    try {
      commit(AppMutation.setLoading, true)
      commit(`energyStatus/${EnergyStatusMutation.setKioskMode}`, true, { root: true }),
      await dispatch(AppAction.loadApiData, projectId)
      await dispatch(`energyStatus/${EnergyStatusAction.connectHub}`, null, { root: true })
      await dispatch(`energyStatus/${EnergyStatusAction.setEnergyStatusItemsFilter}`, () => true, { root: true })
    } finally {
      commit(AppMutation.setLoading, false)
    }
  },
  async [AppAction.loadApiData]({ dispatch, state }, projectId: string): Promise<void> {
    await Promise.all([
      dispatch(`energyStatus/${EnergyStatusAction.loadEnergyStatusData}`, { projectId, language: state.selectedLanguage }, { root: true }),
      dispatch(`resource/${ResourceAction.loadResources}`, { language: state.selectedLanguage, categories: resourceCategories }, { root: true }),
    ])
  },
  async [AppAction.toastInfo]({ commit }, message: string): Promise<void> {
    commit(AppMutation.setToast, ToastModel.info(message))
  },
  async [AppAction.toastWarning]({ commit }, message: string): Promise<void> {
    commit(AppMutation.setToast, ToastModel.warning(message))
  },
  async [AppAction.toastError]({ commit }, message: string): Promise<void> {
    commit(AppMutation.setToast, ToastModel.error(message))
  },
  async [AppAction.hideToast]({ commit }): Promise<void> {
    commit(AppMutation.setToast, null)
  },
  async [AppAction.handleApiError]({ dispatch }, payload: ApiErrorConverterResult): Promise<void> {
    const message = [payload.message, ...payload.details].join('<br>')
    dispatch(AppAction.toastError, message)
  },
  async [AppAction.handleHubReconnecting]({ dispatch, rootGetters }): Promise<void> {
    const stringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    dispatch(AppAction.toastWarning,  stringResource('error.no.connection'))
  },
  async [AppAction.handleHubReconnected]({ dispatch, rootGetters }): Promise<void> {
    const stringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    dispatch(AppAction.toastInfo, stringResource('connection.reconnected'))
    dispatch(`energyStatus/${EnergyStatusAction.startNotifications}`, null, { root: true })
  },
}
