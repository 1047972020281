import Vue from 'vue'
import App from './App.vue'
import store from './store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import SharedComponents from '@ecocoach/shared-components/src'

import { backendEnvironment } from './environment'

import VueLogger from 'vuejs-logger'

import ApiService from '@ecocoach/domain-store-modules/src/services/api.service'

import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'

import HubService from '@ecocoach/domain-store-modules/src/services/hub.service'

import CacheService from '@ecocoach/domain-store-modules/src/services/cache.service'

import { ApiErrorConverter } from '@ecocoach/domain-store-modules/src/helpers/apiErrorConverter'

import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'

import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { AppAction, AppMutation } from './store/modules/app/types'

Vue.use(Vuetify)
Vue.use(SharedComponents)

AppDataStorageService.init({ appId: 'KioskApp', environment: backendEnvironment() })

Vue.use(VueLogger, {
  logLevel: 'debug',
  showLogLevel: true,
  showMethodName: true,
  showConsoleColors: true,
})

// no need to import dictionary in components:
Vue.filter('translate', (value) => store.getters[`resource/${ResourceGetter.dictionary}`](value))

// no need to import svgIcon in components
Vue.filter('resolveIcon', (value) => store.getters[`resource/${ResourceGetter.svgIcon}`](value))

const apiErrorConverter = new ApiErrorConverter({
  resolveStringResource: store.getters[`resource/${ResourceGetter.dictionary}`],
})

initialize()

async function initialize() {
  ApiService.init({ appId: 'KioskApp', environment: backendEnvironment() })
  ApiService.registerErrorHandler(errorContext => store.dispatch(`app/${AppAction.handleApiError}`, apiErrorConverter.convert(errorContext)))
  HubService.registerReconnectingHandler(() => store.dispatch(`app/${AppAction.handleHubReconnecting}`))
  HubService.registerReconnectedHandler(() => store.dispatch(`app/${AppAction.handleHubReconnected}`))

  CacheService.init({ userIdentifier: 'public', environment: backendEnvironment(), version: 1})

  const urlParams = new URLSearchParams(window.location.search)
  const projectIdFromQueryString = urlParams.get('projectId')
  const languageFromQueryString = urlParams.get('language')
  Vue.$log.debug('window.location.search', window.location.search)
  Vue.$log.debug('languageFromQueryString', languageFromQueryString)
  Vue.$log.debug('projectIdFromQueryString', projectIdFromQueryString)

  const language = EnvironmentService.determineLanguage(['de', 'en-uk', 'it'], languageFromQueryString!)
  store.commit(`app/${AppMutation.setLanguage}`, language)
  store.dispatch(`app/${AppAction.load}`, projectIdFromQueryString)

  new Vue({
    store: (store as any),
    render: (h) => h(App),
  }).$mount('#app')
}
