import { MutationTree } from 'vuex'
import { ToastModel } from './models'
import { AppMutation, AppState } from './types'

export const mutations: MutationTree<AppState> = {
  [AppMutation.setLoading](state: AppState, isLoading: boolean) {
    state.isLoading = isLoading
  },
  [AppMutation.setLanguage](state: AppState, language: string) {
    state.selectedLanguage = language
  },
  [AppMutation.setToast](state: AppState, payload: ToastModel | null) {
    state.toast = payload
  },
}
